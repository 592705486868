<template>
  <section class="map text-gray-900">
    <div
      class="map__container w-full h-full flex flex-col items-center justify-center md:min-h-0"
    >
      <GoogleMap
        data-aos="zoom-y-out"
        data-aos-delay="400"
        style="width: 100%; height: 100%"
        theme="minimal"
        :api-key="key"
        :center="center"
        :zoom="13"
        :minZoom="12"
        :maxZoom="17"
        :zoomControl="true"
        :mapTypeControl="false"
        :scaleControl="false"
        :streeViewControl="false"
        :rotateControl="false"
        :fullscreenControl="false"
        :disableDefaultUi="true"
        :keyboardShortcuts="false"
        :restriction="restriction"
      >
        <Marker :options="markerOptions" />
      </GoogleMap>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
export default defineComponent({
  name: "Map",
  components: { GoogleMap, Marker },
  setup() {
    const key = process.env.VUE_APP_map;
    const center = { lat: 51.11742, lng: 17.02425 };
    const mapBounds = {
      north: 51.189569,
      south: 51.023541,
      west: 16.832987,
      east: 17.210059,
    };
    const restriction = {
      latLngBounds: mapBounds,
      strictBounds: false,
    };
    const markerOptions = {
      position: center,
      icon: require("@/assets/images/icons/marker.png"),
      title: "DSV",
    };
    return { key, center, restriction, markerOptions };
  },
});
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 50vh;
}
</style>
