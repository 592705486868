
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
export default defineComponent({
  name: "Map",
  components: { GoogleMap, Marker },
  setup() {
    const key = process.env.VUE_APP_map;
    const center = { lat: 51.11742, lng: 17.02425 };
    const mapBounds = {
      north: 51.189569,
      south: 51.023541,
      west: 16.832987,
      east: 17.210059,
    };
    const restriction = {
      latLngBounds: mapBounds,
      strictBounds: false,
    };
    const markerOptions = {
      position: center,
      icon: require("@/assets/images/icons/marker.png"),
      title: "DSV",
    };
    return { key, center, restriction, markerOptions };
  },
});
